<template>
    <div class="main-box">
        <div class="con-box">
            <div class="title">
                <p>我的服务</p>
            </div>
            <div class="search-box">
                <a-form :model="formSearchA" :label-col="labelCol" :wrapper-col="wrapperCol">
                    <div class="search-con">
                        <div class="con-item">
                            <a-form-item label="服务种类">
                                <a-select v-model:value="fwzl" placeholder="请选择服务种类">
                                    <a-select-option v-for="(sItem, si) in serviceList" :key="si" :value="sItem.BIANMA">{{sItem.NAME}}</a-select-option>
                                </a-select>
                            </a-form-item>
                        </div>
                        <div class="con-item">
                            <a-form-item label="产品名称">
                                <a-input v-model:value="chanpinmingcheng"  placeholder="请输入产品名称" />
                            </a-form-item>
                        </div>
                        <!-- <div class="con-item">
                            <a-form-item :wrapperCol="{span:24}">
                                <a-input-search v-model:value="formSearchA.keywords"  placeholder="请输入搜索内容" />
                            </a-form-item>
                        </div> -->
                        <!-- <div class="con-item">
                            <a-form-item label="服务状态">
                                <a-select v-model:value="formSearchA.serviceStatus" placeholder="请选择服务状态">
                                    <a-select-option value="1">需求状态1</a-select-option>
                                    <a-select-option value="2">需求状态2</a-select-option>
                                </a-select>
                            </a-form-item>
                        </div> -->
                        <div class="con-item">
                           <div class="btn">
                                <a-button @click="getList">确定筛选</a-button>
                           </div>
                        </div>
                    </div>
                </a-form>
            </div>
            <div class="content-box">
                <a-table :columns="columns" :data-source="sList" :loading="loading" :pagination="pagination" @change="onPage">
                    <template #product="{ text, record }">
                        <a @click="$router.push({ path: '/ProdutcDetails', query: { id: record.FINAPROD_ID } })">{{text}}</a>
                    </template>
                    <template #operation="{record}">
                        <a @click="$router.push({path: '/PersonalLayout/MyServiceDetails', query: { id: record.lid }})" v-if="record.CONFIRM_YN === 'Y'">详情</a>
                        <a-button v-if="(record.LINK_WAY === 'BLANK' || record.LINK_WAY === 'SYS') && record.CONFIRM_YN === 'N'" @click="modalShow = true;curItem = record" style="margin-right: 15px;">同意</a-button>
                        <a-button v-if="(record.LINK_WAY === 'BLANK'|| record.LINK_WAY === 'SYS') && record.CONFIRM_YN === 'N'" @click="modalShow1 = true;curItem = record"    >拒绝</a-button>
                    </template>
                </a-table>
            </div>
        </div>
        <a-modal
            :visible="modalShow"
            title="信息提示"
            @cancel="modalShow = false"
            @ok="onStatus('Y')"
            ok-text="确定"
            cancel-text="取消"
        >
            <p>您确定同意此条业务吗？</p>
        </a-modal>
        <a-modal
            :visible="modalShow1"
            title="信息提示"
            @cancel="modalShow1 = false"
            @ok="onStatus('R')"
            ok-text="确定"
            cancel-text="取消"
        >
            <p>您确定拒绝此条业务吗？</p>
        </a-modal>
    </div>
</template>

<script>
import { defineComponent, reactive } from 'vue'
import { mapGetters } from 'vuex'
export default defineComponent({
    computed: {
        ...mapGetters(['orgId', 'isAuth', 'loginType'])
    },
    setup() {
        const columns = [
            {
                title: '序号',
                dataIndex: 'key',
                key: 'key',
                align: 'center',
                width: 60
            },
            {
                title: '机构名称',
                dataIndex: 'jigoumingcheng',
                key: 'jigoumingcheng',
                align: 'center'
            },
            {
                title: '服务种类',
                dataIndex: 'fuwuzhonglei',
                key: 'fuwuzhonglei',
                align: 'center'
            },
            {
                title: '产品名称',
                dataIndex: 'product',
                key: 'product',
                align: 'center',
                slots: {
                    customRender: 'product'
                }
            },
            {
                title: '担保方式',
                dataIndex: 'danbaofangshi',
                key: 'danbaofangshi',
                align: 'center'
            },
            {
                title: '需求额度',
                dataIndex: 'xuqiuedu',
                key: 'xuqiuedu',
                align: 'center'
            },
            {
                title: '放款额度(利率)',
                dataIndex: 'fangkuanedu',
                key: 'fangkuanedu',
                align: 'center'
            },
            {
                title: '对接方式',
                dataIndex: 'duijiefangshi',
                key: 'duijiefangshi',
                align: 'center'
            },
            {
                title: '需求状态',
                dataIndex: 'xuqiuzhuangtai',
                key: 'xuqiuzhuangtai',
                align: 'center'
            },
            {
                title: '经办人员',
                dataIndex: 'jingbanrenyuan',
                key: 'jingbanrenyuan',
                align: 'center'
            },
            {
                title: '受理状态',
                dataIndex: 'shoulizhuangtai',
                key: 'shoulizhuangtai',
                align: 'center'
            },
            {
                title: '操作',
                dataIndex: 'operation',
                align: 'center',
                slots: {
                    customRender: 'operation'
                }
            }
        ]
        //   表单A
        const formSearchA = reactive({
            keywords: '',
            serviceStatus: undefined
        })
        return {
            labelCol: {
                span: 6
            },
            wrapperCol: {
                span: 16
            },
            formSearchA,
            columns
        }
    },
    data () {
        return {
            sList: [],
            serviceList: [],
            fwzl: undefined,
            chanpinmingcheng: '',
            loading: false,
            pagination: {},
            page: 1,
            modalShow: false,
            modalShow1: false,
            curItem: ''
        }
    },
    methods: {
        onStatus (YN) {
            console.log( this.curItem)
            this.$store.dispatch('editProductsStatus', { LINKENTERPROC_ID: this.curItem.lid, CONFIRM_YN: YN, tm: new Date().getTime() }).then(res => {
                if (res.result === 'success') {
                    if (YN === 'Y') {
                        this.$message.success('您已同意此产品的对接，请耐心等待金融机构审核')
                        this.modalShow = false
                    } else if (YN === 'R') {
                        this.$message.success('您已成功拒绝此产品的对接！')
                        this.modalShow1 = false
                    }
                    this.getList()
                } else {
                    this.$message.error('系统异常！')
                }
            })
        },
        getList () {
            this.loading = true
            this.sList = []
            this.$store.dispatch('getSuccessList1', {
                showCount: '-1',
                currentPage: this.page,
                ENTERPRISE_ID: this.orgId,
                SERVICE_COLUMN: this.fwzl,
                PRODUCT_NAME: this.chanpinmingcheng
            }).then(res => {
                this.loading = false
                if (res.result === 'success') {
                    let datas = res.varList
                    if (datas.length != 0) {
                        this.pagination.total = res.page.totalResult
                        for (let i in datas) {
                            let SLZT = ''
                            if (datas[i].AUDIT_STATUS == 0) {
                                SLZT = '未受理'
                            } else if (datas[i].AUDIT_STATUS == 1) {
                                SLZT = '受理中'
                            } else if (datas[i].AUDIT_STATUS == 20) {
                                SLZT = '审批中'
                            } else if (datas[i].AUDIT_STATUS == 30) {
                                SLZT = '已完成'
                            } else if (datas[i].AUDIT_STATUS == -10) {
                                SLZT = '已拒绝'
                            }
                            this.sList.push({
                                lid: datas[i].LINKENTERPROC_ID,
                                FINAPROD_ID: datas[i].FINAPROD_ID,
                                key: parseInt(i) + 1 + ((this.page - 1) * 10),
                                product: datas[i].PRODUCT_NAME,
                                fuwuzhonglei: datas[i].SERVICE_COLUMN,
                                danbaofangshi: datas[i].SERVICE_COLUMN,
                                jigoumingcheng: datas[i].BANKNAME ? datas[i].BANKNAME : '',
                                xuqiuedu: datas[i].QUOTA_E ? datas[i].QUOTA_E  + '万元以下' : '',
                                shoulizhuangtai: datas[i].AUDIT_NAME,
                                LINK_WAY: datas[i].LINK_WAY,
                                CONFIRM_YN: datas[i].CONFIRM_YN,
                                fangkuanedu: (datas[i].RECEIVE_MONEY ? (datas[i].RECEIVE_MONEY + '万(') : '') + (datas[i].INTEREST_RATE ? (datas[i].INTEREST_RATE + '%)') : ''),
                                duijiefangshi: datas[i].LINK_WAY === 'BLANK' ? '机构对接' : datas[i].LINK_WAY === 'OWN' ? '企业申请' : '系统对接',
                                xuqiuzhuangtai: datas[i].CONFIRM_YN === 'Y' ? '企业已确认' : datas[i].CONFIRM_YN === 'R' ? '企业已拒绝' : '企业待确认',
                                jingbanrenyuan: (datas[i].ACCOUEXECU_UNAME ? datas[i].ACCOUEXECU_UNAME : '') + ' ' + (datas[i].ACCOUEXECU_PHONE ? datas[i].ACCOUEXECU_PHONE : '')
                            })
                        }
                    } else {
                        this.sList = []
                        this.pagination.total = 0
                    }
                } else {
                    this.sList = []
                    this.pagination.total = 0
                    this.$message.error('系统异常，获取列表失败！')
                }
            })
        },
        // 翻页
        onPage (pagination) {
            this.page = pagination.current
            this.getList()
        },
        getSelectList () {
            this.serviceList = []
            // 获取服务项下拉选项列表
            this.$store.dispatch('getServiceItemList', { DICTIONARIES_ID: '5e3766b8ee284c929c72cbfeba325b3b', tm: new Date().getTime() }).then(res => {
                if (res.result === 'success') {
                    this.serviceList = res.list
                } else {
                    this.serviceList = []
                }
            })
        }
    },
    created () {
        if (this.loginType === 'qiye' && this.isAuth === 'N') {
            this.$message.info('请先完成企业认证或个体认证后才能进入')
            this.$router.push('/PersonalLayout/WareHouse')
            return false
        }
        this.getList()
        // 获取下拉选项列表
        this.getSelectList()
    }
})
</script>
<style lang="less" scoped>
.content-box ::v-deep(.ant-table-thead){
    background:@color-blue; /* 标准语法 */
}
.content-box ::v-deep(.ant-table-thead th){
    background-color: transparent;
    color: @color-ff;
}

</style>
<style lang="less" scoped>
.main-box{
    width: @main-width-base;
    margin:20px auto;

    .con-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
        .title{
            font-size: @font-lg;
            padding: 0px 10px;
            padding-bottom: 15px;
            border-bottom: @border-base;
            display: flex;
            justify-content: space-between;
            align-items: center;
            p{
                margin-bottom: 0;
            }
        }
        .search-box{
            .search-con{
                padding-top: 15px;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                .con-item{
                    width: 25%;
                    .btn{
                        height: 40px;
                        line-height: 40px;
                        text-align: center;
                        ::v-deep(.ant-btn){
                            padding:0 40px;
                            color: #ffffff;
                            background: @color-blue; /* 标准语法 */
                        }
                    }
                }
            }
        }
        .content-box{
            font-size: 16px;
        }
    }
    .con-box::before{
        content: '';
        position: absolute;
        left: -3px;
        top: 10px;
        width: 6px;
        height: 40px;
        background:@color-blue; /* 标准语法 */
    }
}
</style>
